import '/assets/styles/sections/listing-head.scss';
import PropTypes from 'prop-types';

// Partials
import Placeholder from '/views/partials/placeholder';
import { useMemo } from 'react';

const ListingHead = ({ title, totalCount, subtitle: subtitleProp }) => {
	const subtitle = useMemo(() => (
		subtitleProp ?? (totalCount ? `${totalCount} adet ürün gösteriliyor.` : false)
	), [subtitleProp, totalCount])

	return (
		<header className="section listing-head">
			<div className="wrapper">
				{title ?
					<h1 className="head-title">{title}</h1>
					:
					<Placeholder className="head-title" />
				}
				{subtitle &&
					<strong className="head-subtitle">{subtitle}</strong>
				}
			</div>
		</header>
	)
}

ListingHead.propTypes = {
	title: PropTypes.string,
	totalCount: PropTypes.number,
	subtitle: PropTypes.string,
}

export default ListingHead;