import request from 'pstv-commerce-tools/utilities/request';
import omit from 'lodash/omit';

const productServices = {
	getProduct: ( productId ) => {
		return new Promise((resolve, reject) => {
			request.get(`products/${productId}`, { kind: true }, (payload, status, request) => {
				if (status === 200 && payload?.product) {
					resolve(payload.product);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getCategoryProducts: ( categoryId, query = {} ) => {
		return new Promise((resolve, reject) => {
			request.get(`categories/${categoryId}/products-and-filters`, { ...query }, (payload, status, request) => {
				if (status === 200 && payload?.products) {
					resolve({
						products: payload.products,
						pagination: payload.pagination,
						filters: payload.filters,
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	getCustomListProducts: ( categoryId, query = {} ) => {
		return new Promise((resolve, reject) => {
			request.get(`customlists/${categoryId}/products`, { ...query }, (payload, status, request) => {
				if (status === 200 && payload?.products) {
					resolve({
						products: payload.products,
						customList: payload.custom_list,
						pagination: payload.pagination,
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	getCustomListFilters: ( categoryId, query = {} ) => {
		return new Promise((resolve, reject) => {
			request.get(`customlists/${categoryId}/filters`, { ...query }, (payload, status, request) => {
				if (status === 200 && payload) {
					const workCategory = (cat) => {
						return {
							...omit((cat ?? {}), ['child']),
							children: cat.child ? cat.child.map(child => workCategory(child)) : false,
						}
					}

					resolve({
						...payload,
						categories: payload.category_tree.map(category => workCategory(category)),
					})
					// resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getBrandProducts: ( brandId, query = {} ) => {
		return new Promise((resolve, reject) => {
			request.get(`brand/${brandId}/products`, { ...query }, (payload, status, request) => {
				if (status === 200 && payload?.products) {
					resolve({
						products: payload.products,
						brand: payload.brand,
						pagination: payload.pagination,
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	getSearchProducts: ( term, query = {} ) => {
		return new Promise((resolve, reject) => {
			request.get(`search/products`, { query: term, ...query }, (payload, status, request) => {
				if (status === 200 && payload?.products) {
					resolve({
						products: payload.products,
						pagination: payload.pagination,
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	getSearchFilters: ( term, query = {} ) => {
		return new Promise((resolve, reject) => {
			request.get(`search/filters`, { query: term, ...query }, (payload, status, request) => {
				if (status === 200 && payload) {
					const workCategory = (cat) => {
						return {
							...omit((cat ?? {}), ['child']),
							children: cat.child ? cat.child.map(child => workCategory(child)) : false,
						}
					}

					resolve({
						...payload,
						categories: payload.category_tree.map(category => workCategory(category)),
					})

					// resolve(payload);
					// Ahmet burayı açarsın
					/*
					resolve({
						...payload,
						categories: payload.categories.map(c => {
							if(c.id === 1470) {
								return {
									...c,
									children: [
										payload.categories[34],
										{
											...payload.categories[35],
											children: [
												payload.categories[45],
												payload.categories[46],
											]
										},
										payload.categories[36],
									]
								}
							}
							else {
								return c;
							}
						}).filter(c => ![1471, 1473, 1476, 1615, 1616].includes(c.id))
					});
					 */
				}
				else {
					reject(request);
				}
			});
		});
	},
	getProductRecommendations: (productId) => {
		return new Promise((resolve, reject) => {
			request.get(`products/${productId}/relations`, {}, (payload, status, request) => {
				if (payload?.relations) {
					resolve(payload.relations);
				}
				else {
					reject(request);
				}
			});
		});
	},
	
}

export default productServices;
