import '/assets/styles/sections/listing-filters.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

// Data
import listingOrderOptions from '/data/listing-order-options';

// Context
import { useListing } from 'pstv-commerce-tools/utilities/listing';
import { useBreakpoints } from 'pstv-commerce-tools/utilities/breakpoints';

// Partials
import { Input } from '/views/partials/forms';
import Btn from '/views/partials/btn';

const brandFilterKey = 'metro_brand'

const ListingFiltersModal = loadable(() => import('/views/partials/listing-filters/listing-filtersmodal'), { ssr: false });

const ListingFilters = () => {
	const { query, setFilter, filterData, showFiltersModal } = useListing();
	const { desktop } = useBreakpoints();

	const selectedOrder = useMemo(() => {
		return query?.order ? listingOrderOptions.find(o => o.value === query.order) ?? null : null;
	}, [query])

	return (
		<>
			<ListingFiltersModal />
			<header className="section listing-filters">
				<div className="wrapper filters-wrap">
					{filterData?.filters.length > 0 &&
						<>
							{desktop &&
								<>
									{filterData.filters.map(filter => {
										if(filter.listInSummary) {
											return (
												<Btn
													key={filter.key}
													className="filters-sumbtn outline"
													onClick={() => {
														showFiltersModal(filter.key)
													}}
													afterIcon="angle-right">
													{filter.title}
												</Btn>
											);
										}
										
										return null;
									})}
								</>
							}
							<Btn
								className="filters-togglebtn outline"
								icon="plus"
								onClick={() => { showFiltersModal(); }}
								afterIcon="filter">
								Filtrele
								{filterData.activeFilters.length > 0 &&
									<span className="togglebtn-count">({filterData.activeFilters.length})</span>
								} 
							</Btn>
						</>
					}
					<Input
						toggle
						className="filters-filter-inhousebrand"
						type="checkbox"
						uncheckedValue={false}
						defaultValue="1"
						onChange={value => {
							setFilter(brandFilterKey, value)
						}}
						checked={!!query[brandFilterKey]}
						label="Metro Markalı Ürünleri Göster" />
					<Input
						className="filters-filter-order"
						type="select"
						onChange={(opt) => { setFilter('order', opt?.value ?? false); }}
						placeholder="Sıralama"
						value={selectedOrder}
						options={listingOrderOptions}
						name="order" />
				</div>
			</header>
		</>
	)
}

ListingFilters.propTypes = {
	title: PropTypes.string,
}

export default ListingFilters;
