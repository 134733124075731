const listingOrderOpts = [
	{
		value: 'opa',
		label: 'En düşük fiyat',
	},
	{
		value: 'opd',
		label: 'En yüksek fiyat',
	},
	{
		value: 'ona',
		label: 'A\'dan Z\'ye',
	},
	{
		value: 'ond',
		label: 'Z\'den A\'ya',
	},
]

export default listingOrderOpts